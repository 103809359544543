import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './css/CompanyDetailsPage.css';
import logo from './css/images.png';
import Lottie from 'react-lottie';
import loader from './css/loader.json';  // Path to your loader animation JSON file

const CompanyDetailPage = () => {
  const [companyDetails, setCompanyDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [selectedCompany, setSelectedCompany] = useState(null);

  const navigate = useNavigate();

  const queryParams = new URLSearchParams(window.location.search);
  const fromDate = queryParams.get('fromDate');
  const toDate = queryParams.get('toDate');
  const companyNameFromQuery = queryParams.get('companyName');
  const compCode = queryParams.get('compCode');

  // Lottie options for the loader animation
  const lottieOptions = {
    loop: true,          // The animation will loop continuously
    autoplay: true,      // The animation will start automatically
    animationData: loader,  // Path to your Lottie JSON file
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice', // Maintain aspect ratio
    },
  };

  useEffect(() => {
    async function fetchCompanyDetails() {
      try {
        const accessToken = localStorage.getItem('accessToken'); // Retrieve the token
        const userId = localStorage.getItem('userId'); // Retrieve the user ID
        const response = await fetch(`http://dsapp.shanti-pos.com:8080/api/company-details?fromDate=${encodeURIComponent(fromDate)}&toDate=${encodeURIComponent(toDate)}&compCode=${encodeURIComponent(compCode)}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`, // Include the token
            'UserId': userId ? userId : '', // Include the user ID
          }
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        if (data.length === 0) {
          setError('No data found for the specified date range');
        } else {
          setCompanyDetails(data);
          if (companyNameFromQuery) {
            const selected = data.find(c => c.Name === companyNameFromQuery);
            setSelectedCompany(selected);
          }
        }
      } catch (error) {
        console.error('Error fetching company details:', error);
        setError('Error fetching company details');
      } finally {
        setLoading(false);
      }
    }

    fetchCompanyDetails();
  }, [fromDate, toDate, companyNameFromQuery, compCode]);

  const handleCompanyClick = (company) => {
    localStorage.setItem('selectedCompany', JSON.stringify(company));
    localStorage.setItem('fromDate', fromDate);
    localStorage.setItem('toDate', toDate);
    localStorage.setItem('compCode', compCode);

    const storedCompanies = JSON.parse(localStorage.getItem('companyNames')) || [];
    if (!storedCompanies.includes(company.Name)) {
      storedCompanies.push(company.Name);
      localStorage.setItem('companyNames', JSON.stringify(storedCompanies));
    }

    navigate(`/dashboard?companyName=${encodeURIComponent(company.Name)}&fromDate=${encodeURIComponent(fromDate)}&toDate=${encodeURIComponent(toDate)}&compCode=${encodeURIComponent(compCode)}`);
  };

  if (loading) return <Lottie options={lottieOptions} height={100} width={100} />;  // Show the loader animation
  if (error) return <p>{error}</p>;

  return (
    <div className="table-container">
      <img src={logo} alt="DeviSoft Logo" className="logo" />
      {selectedCompany && (
        <div className="company-detail">
          <h2>{selectedCompany.Name}</h2>
          {/* Render more company details here */}
        </div>
      )}
      {companyDetails.length === 0 ? (
        <p className="no-data">No data found for the selected date range.</p>
      ) : (
        <table className="table">
          <thead>
            <tr>
              <th>Company Names</th>
            </tr>
          </thead>
          <tbody>
            {companyDetails.map((company, index) => (
              <tr key={index}>
                <td>
                  <button
                    onClick={() => handleCompanyClick(company)}
                    style={{ background: 'none', border: 'none', color: '#007bff', cursor: 'pointer', textDecoration: 'underline' }}
                  >
                    {company.Name}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default CompanyDetailPage;
