import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Importing useNavigate for redirection
import { useAuth } from '../../context/AuthContext.js';  // Importing useAuth from context
import './AdminLogin.css';
import Logo from '../css/DSLogo.png'

const AdminLogin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate(); // useNavigate hook for programmatic navigation
  const { login } = useAuth(); // Accessing the login function from AuthContext

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post('http://dsapp.shanti-pos.com:8080/admin/login', { email, password });
      const { admintoken, _id } = response.data; // Assuming the token is in response.data.token

      // Store the token and userId in localStorage
      localStorage.setItem('adminToken', admintoken);
      localStorage.setItem('userId', _id);

      // Update the AuthContext to reflect the login state
      login();  // This will set isAuthenticated to true

      setError('');
      setLoading(false);

      // Redirect to /system-admin page
      navigate('/system-admin');
    } catch (err) {
      setError('Invalid credentials');
      setLoading(false);
    }
  };

  return (
    <div className="admin-login-container">
     <h2>
        {/* Render the logo as an image */}
        <img src={Logo} alt="DS Logo" className="admin-login-logo" />
        Admin Login
      </h2>
      <form onSubmit={handleSubmit}>
        <div className="input-group">
          <label htmlFor="email">Email</label>
          <input
            id="email"
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>

        <div className="input-group">
          <label htmlFor="password">Password</label>
          <input
            id="password"
            type="password"
            placeholder="Enter your password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>

        <button type="submit" disabled={loading}>
          {loading ? 'Logging In...' : 'Login'}
        </button>
      </form>

      {error && <p className="error">{error}</p>}
    </div>
  );
};

export default AdminLogin;
