import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './css/YearSelectPage.css';
import logo from './css/images.png';
import Lottie from 'react-lottie';
import loader from './css/loader.json';  // Path to your loader animation JSON file

const YearSelectPage = () => {
  const [financialYears, setFinancialYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(''); // State for handling error
  const navigate = useNavigate();



  useEffect(() => {
    async function fetchFinancialYears() {
      setLoading(true);
      setError(''); // Reset error state before making a new request
      try {
        const accessToken = localStorage.getItem('accessToken');
        const userId = localStorage.getItem('userId');

        const response = await fetch(`http://dsapp.shanti-pos.com:8080/api/financial-years`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
            'UserId': userId ? userId : '',
          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();

        if (data.length === 0) {
          throw new Error('No financial years available, check database connection');
        }

        setFinancialYears(data);
        setSelectedYear(data.length > 0 ? data[0].fyear : '');
      } catch (error) {
        console.error('Error fetching financial years:', error);
        setError(error.message || 'Failed to load financial years. Check database connection.');
      } finally {
        setLoading(false);
      }
    }

    fetchFinancialYears();
  }, []);

  const handleChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (selectedYear) {
      const [fromDate, toDate] = selectedYear.split(' TO ');
      navigate(`/company-details?fromDate=${encodeURIComponent(fromDate)}&toDate=${encodeURIComponent(toDate)}`);
    }
  };

  return (
    <div className="year-select-container">
      {loading && (
    <div className="loader-overlay">
      <div className="loader-container">
        <Lottie options={{ animationData: loader, loop: true, autoplay: true }} height={100} width={100} />
      </div>
    </div>
  )}

      <img src={logo} alt="DeviSoft Logo" className="logo" />
      <h1 className="message">Select a Financial Year</h1><br />
      
      {error && <div className="error-message">{error}</div>} {/* Show error message */}
      
      <form className="year-select-form" onSubmit={handleSubmit}>
        <select className="year-select" id="year-select" value={selectedYear} onChange={handleChange} disabled={loading}>
          {financialYears.length === 0 ? (
            <option value="">No financial years available</option>
          ) : (
            financialYears.map((year, index) => (
              <option key={index} value={year.fyear}>
                {year.fyear}
              </option>
            ))
          )}
        </select>
        
        <button className="submit-button" type="submit" disabled={loading || financialYears.length === 0}>
          {loading ? 'Loading...' : 'Submit'}
        </button>
      </form>
    </div>
  );
};

export default YearSelectPage;
