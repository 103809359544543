import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaUser, FaUsers, FaSignOutAlt } from 'react-icons/fa'; // FontAwesome Icons
import './SideBar.css';
import Logo from '../css/images.png'

const Sidebar = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    // Clear everything from localStorage and sessionStorage
    localStorage.clear();  // Clears all items from localStorage
    sessionStorage.clear();  // Clears all items from sessionStorage
    localStorage.setItem('isAuthenticated', 'false');  // Set isAuthenticated to false in localStorage
    navigate('/admin-login'); // Navigate to the login page
    window.location.reload(); 
  };
  

  return (
    <div className="sidebar">
      <div className="sidebar-header">
      <img src={Logo} alt="Logo" className="sidebar-logo" />
      </div>
      <ul className="sidebar-links">
        <li>
          <Link to="/system-admin">
            <FaUser className="icon" />
            New User
          </Link>
        </li>
        <li>
          <Link to="/get-user">
            <FaUsers className="icon" />
            View Users
          </Link>
        </li>
        <li>
          <button onClick={handleLogout} className="logout-btn">
            <FaSignOutAlt className="icon" />
            Logout
          </button>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
