import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../css/StockReport.css';
import Sidebar from '../Dashboard/SideBar.js';

const StockReport = () => {
  const [formData, setFormData] = useState({
    department: '',
    group: '',
    brand: '',
    design: '',
    color: '',
    size: '',
    pattern: '',
    season: ''
  });

  const [suggestions, setSuggestions] = useState({
    department: [],
    group: [],
    brand: [],
    design: [],
    color: [],
    size: [],
    pattern: [],
    season: []
  });

  const [selectedIndex, setSelectedIndex] = useState(-1);  // Track the highlighted suggestion
  const suggestionRefs = useRef({});  // Store refs for each suggestion list
  const navigate = useNavigate();

  const userId = localStorage.getItem('userId');
  const accessToken = localStorage.getItem('accessToken');
  const selectedCompany = JSON.parse(localStorage.getItem('selectedCompany'));

  // Validate early, but don't return early
  if (!userId || !accessToken) {
    console.error('Missing userId or accessToken in localStorage');
  }

  if (!selectedCompany || !selectedCompany.CompCode) {
    console.error('Company code is missing or invalid');
  }

  // Make sure the hooks are always called before the conditional returns.
  const CompCode = selectedCompany ? selectedCompany.CompCode : null;

  // Dynamic Fetch Function (fetch suggestions)
  const fetchSuggestions = async (field, searchTerm) => {
    if (!searchTerm) {
      setSuggestions((prev) => ({ ...prev, [field]: [] }));
      return;
    }

    try {
      const response = await axios.get(`http://dsapp.shanti-pos.com:8080/api/getAll${field}s?CompCode=${CompCode}&${field}=${searchTerm}`, {
        headers: {
          'Content-Type': 'application/json',
          'UserId': userId,
          'Authorization': `Bearer ${accessToken}`
        }
      });

      setSuggestions((prev) => ({ ...prev, [field]: response.data }));
    } catch (error) {
      console.error(`Error fetching ${field} suggestions:`, error);
    }
  };

  // Handle input change and fetch corresponding suggestions
  const handleChange = async (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Reset selected index when typing a new value
    setSelectedIndex(-1);

    // Call fetchSuggestions dynamically based on the name of the field
    if (value) {
      await fetchSuggestions(name, value);
    }
  };

  // Handle suggestion click, store SRNO and show NAME in the input field
  const handleSuggestionClick = (field, suggestion) => {
    // Set SRNO in formData, but show NAME in the input field
    setFormData({
      ...formData,
      [field]: suggestion.NAME // Show the NAME in the input field
    });

    // Store SRNO for navigation
    setFormData((prevData) => ({
      ...prevData,
      [`${field}SRNO`]: suggestion.SRNO // Store the SRNO in the formData state
    }));

    // Clear suggestions after selection
    setSuggestions((prev) => ({ ...prev, [field]: [] }));
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    // Build query string with SRNO values from the formData
    const queryParams = new URLSearchParams({
      departmentcode: formData.departmentSRNO || '',  // Use the SRNO value for department
      groupcode: formData.groupSRNO || '',            // Use the SRNO value for group
      brandcode: formData.brandSRNO || '',            // Use the SRNO value for brand
      designcode: formData.designSRNO || '',          // Use the SRNO value for design
      colorcode: formData.colorSRNO || '',            // Use the SRNO value for color
      sizecode: formData.sizeSRNO || '',              // Use the SRNO value for size
      patterncode: formData.patternSRNO || '',        // Use the SRNO value for pattern
      seasoncode: formData.seasonSRNO || ''           // Use the SRNO value for season
    });

    // Navigate to the /stock page with query parameters
    navigate(`/stock?${queryParams.toString()}`);
  };

  // Render suggestion lists dynamically
  const renderSuggestions = (field) => {
    const fieldSuggestions = suggestions[field];
    if (fieldSuggestions.length > 0) {
      return (
        <ul className="suggestion-list">
          {fieldSuggestions.map((suggestion, index) => (
            <li
              key={suggestion.SRNO}
              onClick={() => handleSuggestionClick(field, suggestion)}
              className={selectedIndex === index ? 'highlighted' : ''} // Apply 'highlighted' class to the selected suggestion
              ref={(el) => suggestionRefs.current[index] = el}  // Set ref for each suggestion item
            >
              {suggestion.NAME} {/* Show the NAME to the user */}
            </li>
          ))}
        </ul>
      );
    }
  };

  // Handle keyboard navigation (ArrowUp, ArrowDown, Enter)
  const handleKeyDown = (e, field) => {
    const fieldSuggestions = suggestions[field];
    if (fieldSuggestions.length === 0) return;

    if (e.key === 'ArrowDown') {
      setSelectedIndex((prevIndex) => Math.min(prevIndex + 1, fieldSuggestions.length - 1)); // Move down
    } else if (e.key === 'ArrowUp') {
      setSelectedIndex((prevIndex) => Math.max(prevIndex - 1, 0)); // Move up
    } else if (e.key === 'Enter' && selectedIndex >= 0) {
      // Select the highlighted suggestion on Enter key press
      e.preventDefault();  // Prevent form submission
      handleSuggestionClick(field, fieldSuggestions[selectedIndex]);
    }
  };

  // Scroll the selected item into view when selectedIndex changes
  useEffect(() => {
    if (selectedIndex >= 0 && suggestionRefs.current[selectedIndex]) {
      suggestionRefs.current[selectedIndex].scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  }, [selectedIndex]);

  return (
    <div>
      <Sidebar />
      <div className="stock-report">
        <h1>Stock Report</h1>
        <form autocomplete="off" onSubmit={handleSubmit} className="stock-report-form">
          {['department', 'group', 'brand', 'design', 'color', 'size', 'pattern', 'season'].map((field) => (
            <div key={field}>
              <label>
                {field.charAt(0).toUpperCase() + field.slice(1)}:
                <input
                  type="text"
                  name={field}
                  value={formData[field]}  // Show the NAME value to the user
                  onChange={handleChange}
                  onKeyDown={(e) => handleKeyDown(e, field)}  // Attach keydown handler
                />
              </label>
              {renderSuggestions(field)}  {/* Render suggestions below the input */}
            </div>
          ))}

          <button type="submit">Generate</button>
        </form>
      </div>
    </div>
  );
};

export default StockReport;
