import React from 'react';
import '../css/Header.css'; 

const Header = () => {
  // Retrieve data from local storage
  const companyName = localStorage.getItem('companyName') || 'Company Name';
  const fromDate = localStorage.getItem('fromDate') || 'From Date';
  const toDate = localStorage.getItem('toDate') || 'To Date';

  return (
    <header className="header">
      <div className="header-company-name">{companyName}</div>
      <div className="header-date-range">
        Period: {fromDate} - {toDate}
      </div>
    </header>
  );
};

export default Header;
