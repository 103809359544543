import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom
import './ShowUser.css';
import Sidebar from './SideBar';

const SystemAdminsTable = () => {
  const [admins, setAdmins] = useState([]);
  const navigate = useNavigate(); // Initialize the useNavigate hook

  useEffect(() => {
    axios.get('http://dsapp.shanti-pos.com:8080/user/system-admins')
      .then(response => {
        setAdmins(response.data);  // assuming response.data is an array of admin objects
      })
      .catch(error => {
        console.error('There was an error fetching the data:', error);
      });
  }, []);

  const handleEdit = (id) => {
    navigate(`/edit-user/${id}`);  // Navigate to the edit page for the selected admin
  };

  return (
    <div className="table-container-admin">
        <Sidebar/>
      <h2>Users</h2>
      <table className="admins-table">
        <thead>
          <tr>
            <th>Email</th>
            <th>Company Name</th>
            <th>Contact Person</th>
            <th>Contact Number</th>
            <th>Default DB</th>
            <th>DB Username</th>
            <th>Static IP</th>
            <th>Port</th>
            <th>Edit</th>
          </tr>
        </thead>
        <tbody>
          {admins.length > 0 ? admins.map((admin) => (
            <tr key={admin._id}>
              <td>{admin.email}</td>
              <td>{admin.companyName}</td>
              <td>{admin.contactPersonName}</td>
              <td>{admin.contactPersonNumber}</td>
              <td>{admin.defaultDB}</td>
              <td>{admin.dbUsername}</td>
              <td>{admin.staticIP}</td>
              <td>{admin.port}</td>
              <td>
                <button className="edit-btn" onClick={() => handleEdit(admin._id)}>
                  Edit
                </button>
              </td>
            </tr>
          )) : (
            <tr>
              <td colSpan="9">Loading data...</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default SystemAdminsTable;
